<template lang="pug">
  #home
    header#header
      .mainImage
        img.pc(src="../assets/main.png")
        img.sp(src="../assets/mainsp.png")
    main.main
      h2
        i.icon
        | 甘露煮くり子
      p 突然生まれた栗の甘露煮さんの世界。
      p
        | いろんなことに興味があるくり子さん。
        br
        | 甘露煮ならではの仕事をがんばるよ。
      p
        | 甘露煮くり子さん
        br
        | お友達もできるかな。

      h2 
        i.icon
        | ギャラリー
      carousel(:autoplay="true" :perPage="parpage" :loop="true")
        slide
          img(src="../assets/01.png")
        slide
          img(src="../assets/02.png")
        slide
          img(src="../assets/03.png")
        slide
          img(src="../assets/04.png")
        slide
          img(src="../assets/05.png")
        slide
          img(src="../assets/06.png")
        slide
          img(src="../assets/07.png")
        slide
          img(src="../assets/08.png")
        slide
          img(src="../assets/09.png")
        slide
          img(src="../assets/10.png")
        slide
          img(src="../assets/11.png")
        slide
          img(src="../assets/12.png")
        slide
          img(src="../assets/13.png")
        slide
          img(src="../assets/14.png")
    #footer
      p 
      p.copy copyright ©きいろ信号とまる
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import UAParser from 'ua-parser-js';

export default {
  name: 'Home',
  components: {
    Carousel,
    Slide
  },
  data(){
    return {
      parpage: 5
    }
  },
  mounted(){
    if( new UAParser().getOS().name == 'iOS' || new UAParser().getOS().name == 'Android' ){
      this.parpage = 1;
    }
  }
}
</script>

<style lang="scss">
.mainImage {
  text-align: center;
  .sp {
    display: none;
  }
  .pc {
    display: inline;
  }
}
.main {
  text-align: center;
  h2 {
    color: #444;
    padding-top: 30px;
    position: relative;
    .icon {
      position: relative;
      top: 12px;
      display: inline-block;
      width: 45px;
      height: 45px;
      background: url("../assets/head.svg") no-repeat;
    }
  }
  p {padding-top: 30px;}
}
#footer {
  margin-top: 40px;
  padding-bottom: 40px;
  .copy {
    text-align: center;
  }
}
@media (max-width: 834px) {
  .mainImage {
    img {
      width: 100%;
    }
    .sp {
      display: inline;
    }
    .pc {
      display: none;
    }

  }
}
</style>
